import React from "react"
import Faq from "@components/Faq"
import { ThemeProvider } from "../components/themeContext"
import Header from "@components/Header"
import Footer from "@components/Footer"
import HeaderPromotion from "../components/HeaderPromotion"
import Layout from "../components/Layout"

export default () => {
  return (
    <ThemeProvider>
      <Layout>
        <HeaderPromotion />
        <Header />
        <Faq />
        <Footer />
      </Layout>
    </ThemeProvider>
  )
}
